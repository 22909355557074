import React, { useState } from 'react';
import { useRelatedDocuments, useVideoMetadata } from '../../fetch/fetchMetadata';
import { useNavigate } from 'react-router-dom';
import { useQuarterThumbImage } from '../../fetch/fetchImage';
import { useTheme } from '@emotion/react';

export default function RelatedDocumentsMenu({ partNumber }) {
    const theme = useTheme();
    const { related, isLoading: isRelatedLoading } = useRelatedDocuments(partNumber);

    if (!related || related.length === 0 || isRelatedLoading) {
        return null;
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: theme.appBar.height + theme.appBar.buffer,
                right: 32,
                color: 'white',
                maxWidth: '3.5em',
                textAlign: 'center',
                zIndex: 999,
            }}
        >
            <div style={{ fontWeight: 'bold' }}>Related</div>
            {related.map(item => {
                return (
                    <RelatedDocument key={item.id} document={item} />
                );
            })}
        </div>
    );
}

function RelatedDocument({ document }) {
    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const { partNumber, documentTypeId, title } = document;
    const { image, isLoading } = useQuarterThumbImage(documentTypeId != 7 ? partNumber : null);

    const { metadata, isLoading: isVideoMetadataLoading } = useVideoMetadata(documentTypeId == 7 ? partNumber : null);
    const { isYouTube, posterUrl } = metadata;

    const handleClick = () => navigate(documentTypeId != 7 ? `/literature/view/${partNumber}` : `/literature/video/${partNumber}`);

    if (documentTypeId == 7 && !isVideoMetadataLoading) {
        return (
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{ marginTop: '1em' }}
            >
                {isYouTube ? (
                    <img
                        alt={title}
                        title={title}
                        src={`https://img.youtube.com/vi/${partNumber}/hqdefault.jpg`}
                        style={{
                            opacity: !isHovered ? '0.7' : '1',
                            width: !isHovered ? '100%' : '150%',
                            height: '100%',
                            cursor: 'pointer',
                            marginLeft: isHovered ? '-25%' : null
                        }}
                        onClick={handleClick}
                    />
                ) : (
                    <img
                        alt={title}
                        title={title}
                        src={posterUrl}
                        style={{
                            opacity: !isHovered ? '0.7' : '1',
                            width: !isHovered ? '100%' : '150%',
                            height: '100%',
                            cursor: 'pointer',
                            marginLeft: isHovered ? '-25%' : null
                        }}
                        onClick={handleClick}
                    />
                )}
            </div>
        );
    }

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ marginTop: '1em' }}
        >
            <img
                alt={title}
                title={title}
                src={image}
                style={{
                    opacity: !isHovered ? '0.7' : '1',
                    width: !isHovered ? '100%' : '150%',
                    height: '100%',
                    display: !!image && !isLoading ? 'block' : 'none',
                    cursor: 'pointer',
                    marginLeft: isHovered ? '-25%' : null
                }}
                onClick={handleClick}
            />
        </div>
    );
}