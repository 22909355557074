import { getAuthenticatedHeaders } from './fetchHeaders';

export const logDocumentOpen = (partNumber, userLanguage, section, hasActivationKey) => {
    return getAuthenticatedHeaders().then((headers) => {
        let uri = `/insights/logDocumentOpen?partNumber=${partNumber}&userLanguage=${userLanguage}`;
        if (!!section) {
            uri += `&section=${section}`;
        }
        if(hasActivationKey) {
            uri += '&fromG3=true';
        }
        return fetch(uri, { headers, method: 'post' })
    });
}

export const logDocumentDownload = (partNumber, userLanguage, section) => {
    return getAuthenticatedHeaders().then((headers) => {
        let uri = `/insights/logDocumentDownload?partNumber=${partNumber}&userLanguage=${userLanguage}`;
        if(!!section) {
            uri += `&section=${encodeURIComponent(section)}`;
        }
        return fetch(uri, { headers, method: 'post' })
    });
}

export const logDownloadFailed = (partNumber) => {
    return getAuthenticatedHeaders().then((headers) => {
        const uri = `/insights/logDownloadFailed?partNumber=${partNumber}`;
        return fetch(uri, { headers, method: 'post' })
    });
}

export const logFilterUsed = (filterType, filterValue) => {
    if (!filterValue) {
        return;
    }

    return getAuthenticatedHeaders().then((headers) => {
        return fetch(`/insights/logFilterUsed?filterType=${filterType}&filterValue=${filterValue}`, { headers, method: 'post' })
    });
}

export const logDisplayDevice = (os, mobile, browser, deviceType) => {
    return getAuthenticatedHeaders().then((headers) => {
        let uri = `/insights/logDisplayDevice/${deviceType}?browser=${browser}&os=${os}`;
        if (!!mobile) {
            uri += `&mobile=${mobile}`;
        }
        return fetch(uri, { headers, method: 'post' })
    });
}

export const logFeatureUsage = (feature) => {
    return getAuthenticatedHeaders().then((headers) => {
        let uri = `/insights/LogFeatureUsed?feature=${feature}`;
        return fetch(uri, { headers, method: 'post' })
    });
}


export const logListView = (mode, type, query, selectedFamilies, selectedGroups, year) => {
    return getAuthenticatedHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return fetch(
            `/insights/logListView`,
            {
                headers,
                method: 'post',
                body: JSON.stringify({
                    listMode: mode,
                    documentType: `${type}`,
                    isSearch: !!query,
                    query,
                    isFamilyFiltered: !!selectedFamilies,
                    selectedFamilies,
                    isGroupFiltered: !!selectedGroups,
                    selectedGroups,
                    year: year
                })
            })
    });
}