import React from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid } from "@mui/material";
import PageTitle from "./pageTitle";
import './g3Components.css';
import DownloadLink from "./downloadLink";

export default function G3Components() {
    return (
        <ContentContainer>
            <PageTitle title='Mercury Computer Diagnostic Systems Part Numbers' />

            <Box component="main" sx={{ p: 3, width: '100%' }} className="g3-components">
                <Grid container>
                    <Grid item xs={12}>
                        <div className='component-list'>
                            <ul>
                                <li>
                                    <strong>CDS G3 License Key P/N: 8M0234005</strong>
                                    <span>
                                        One must be ordered for each device running CDS G3
                                        <br />
                                        <img title='CDS G3 License Key P/N: 8M0234005' src='g3-license-card-2024.png' />
                                    </span>
                                </li>
                                <li>
                                    <strong>CDS G3 Kit P/N: 8M0168921</strong>
                                    <span>
                                        Includes cables, and SmartCraft interface, does NOT include License
                                        <br />
                                        <img title='CDS G3 License Key P/N: 8M0215503' src='g3-smartcraft-kit.jpg' style={{ maxHeight: '150px' }} />
                                    </span>
                                    <ol type='a'>
                                        <li style={{ lineHeight: 1, marginBottom: '0.25em' }}>CDS G3 SmartCraft diagnostic interface - 898289T81</li>
                                        <li style={{ lineHeight: 1, marginBottom: '0.25em' }}>CAN P/CAN H adapter harness - 898289T83</li>
                                        <li style={{ lineHeight: 1, marginBottom: '0.25em' }}>CDS G3 engine harness adapter (with termination resistor) - 8M0046081</li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>CDS G3 Serial Kit P/N: 8M0137534</strong>
                                    <span>
                                        Includes serial cables, and serial interface, does NOT include License
                                        <br />
                                        <img title='CDS G3 License Key P/N: 8M0215503' src='serial-kit.jpg' />
                                    </span>
                                </li>
                                <li>
                                    <strong>MDDT Kit P/N: 8M0133211</strong>
                                    <span>
                                        <img title='MDDT P/N: 8M0133211' src='mddt.jpg' />
                                    </span>
                                    <br />
                                    <DownloadLink fileName='MDDT_install_Manual_Rev_B_20180730_3.pdf' label='MDDT Installation Manual' />
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Box >

        </ContentContainer>
    )
}