import React from 'react';
import { MenuItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export default function DownloadSectionMenuItem({ partNumber, hasCover, item, subSections, onDownload }) {

    const prefixIndex = item.indexOf(' - ');
    const prefix = prefixIndex >= 1 ? item.substring(0, prefixIndex) : null;
    const title = !!prefix ? item.replace(prefix + ' - ', '') : item;
    const fileName = `section${prefix}_.pdf`;
    const displayName = `${prefix} ${title}`;

    const startPage = hasCover ? subSections[0].start + 1 : subSections[0].start;
    const endPage = hasCover ? subSections[subSections.length - 1].end + 1 : subSections[subSections.length - 1].end;
    return (
        <MenuItem
            onClick={() => onDownload(fileName, startPage, endPage, displayName)}
        >
            <Typography variant="body2" sx={{ color: 'text.secondary', marginRight: '0.5em' }}>
                {prefix}
            </Typography>
            <ListItemText>{title}</ListItemText>
            <ListItemIcon style={{ marginLeft: '0.5em', minWidth: '24px' }}>
                <DownloadIcon fontSize="small" />
            </ListItemIcon>
        </MenuItem>
    )
}