import { Stack } from "@mui/material";
import React from "react";
import DocumentImagePane from "./DocumentImagePane";
import ScrollSectionPane from "./ScrollSectionPane";

export default function DocumentScrollSection({
    section, previousSection, nextSection, setPageNumber,
    hasCover, partNumber, pages, pageCount, documentDimensions,
    onToggleZoom, scrollRef }) {
    const { overSizeFile } = section;

    const handleComeIntoVisibility = (pageNumber) => {
        const scrollTop = scrollRef.current.scrollTop
        window.location.hash = `page=${pageNumber}`;
        scrollRef.current.scrollTop = scrollTop;
    }

    if (overSizeFile) {
        return (
            <Stack justifyContent='center' style={{ minHeight: documentDimensions.documentHeight * pages.length }}>
                {pages.map((page) => {
                    const { pageNumber } = page;
                    return (
                        <DocumentImagePane
                            key={pageNumber}
                            hasCover={hasCover}
                            pageCount={pageCount}
                            documentDimensions={documentDimensions}
                            partNumber={partNumber}
                            pageNumber={pageNumber}
                            onComeIntoVisibility={handleComeIntoVisibility}
                            setPageNumber={setPageNumber}
                            onToggleZoom={onToggleZoom}
                            page={page}
                        />
                    );
                })}
            </Stack>
        );
    }

    return (
        <ScrollSectionPane
            section={section}
            partNumber={partNumber}
            pages={pages}
            hasCover={hasCover}
            pageCount={pageCount}
            documentDimensions={documentDimensions}
            onComeIntoVisibility={handleComeIntoVisibility}
            setPageNumber={setPageNumber}

            previousSection={previousSection}
            nextSection={nextSection}
            onToggleZoom={onToggleZoom}
        />
    );
}