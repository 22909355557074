import React from 'react';
import { ListItem } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilter, clearQuery, executeQuery } from '../../redux/libraryFiltersSlice';
import SearchField from '../Search';

export default function DocumentSearchListItem() {
    const dispatch = useDispatch();
    const { query: executedQuery, searchTextBoxValue: workingQuery } = useSelector((state) => state.libraryFilters)

    const debouncedExecutedQuery = useDebouncedCallback(() => {
        handleExecuteQuery();
    }, 1000)

    const handleExecuteQuery = () => {
        dispatch(executeQuery(workingQuery))
    }

    const handleClearQuery = () => {
        dispatch(clearQuery())
    }

    const handleFilterValueChanged = (value) => {
        dispatch(changeFilter({ fieldName: 'searchTextBoxValue', value }));
    }

    return (
        <ListItem>
                <SearchField
                    placeholder='Search Keyword, Serial, Model'
                    query={workingQuery}
                    onChange={(event) => {
                        handleFilterValueChanged(event.target.value);
                        debouncedExecutedQuery();
                    }}
                    onKeyDown={(event) => {
                        if (event.key == 'Enter') {
                            handleExecuteQuery();
                        }
                    }}
                    onClearQuery={handleClearQuery}
                    showClear={!!executedQuery}
                    isLightVersion
                />
        </ListItem>
    )
}