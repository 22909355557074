import React, { useRef } from "react";
import PdfPageButton from "../core/PdfPageButton";
import PdfView from "./PdfView";
import { DocumentScrollViewer, DocumentPane } from "../../documentViewCore";
import { useIsSinglePage } from "../../../hooks";

export default function DocumentPageView({
    documentDimensions, currentPage, hasCover, partNumber,
    currentPageOffset, isNextPageInCurrentSection, nextPageOffset,
    currentSection, nextSection, setPageNumber, pageCount,
    hasLeftPage, hasRightPage, pages
}) {
    const { documentHeight, documentWidth } = documentDimensions;
    const { isSinglePageMode, isExpandMode } = useIsSinglePage();

    const scrollRef = useRef(null);

    const sectionOneHasNextPage = isNextPageInCurrentSection && !isSinglePageMode;
    const sectionTwoHasPage = !isNextPageInCurrentSection && !isSinglePageMode && !!nextSection;

    const nextPageStart = !isSinglePageMode ? currentPage + 2 : currentPage + 1;
    const scrollContainerWdith = isSinglePageMode || (!hasCover && currentPage == 0) ? documentWidth : documentWidth * 2;

    const handleSetPageNumber = (pageNumber) => {
        setPageNumber(pageNumber);

        if (isExpandMode && !!scrollRef.current) {
            const scrollerNode = scrollRef.current;
            scrollerNode.scrollTop = 0;
        }
    }

    const containerRatio = Math.round((documentHeight / documentWidth) * 10) / 10;

    const currentPageMetadata = (pages || []).filter(it => it.pageNumber === currentPage)[0];
    const currentPageRatioMisMatch = !!currentPageMetadata && (Math.round((currentPageMetadata.pageHeight / currentPageMetadata.pageWidth) * 10) / 10)!== containerRatio;

    let nextPageMetadata = null;
    if (hasRightPage) {
        nextPageMetadata = (pages || []).filter(it => it.pageNumber === currentPage + 1)[0];
    }
    const nextPageRatioMisMatch = !!nextPageMetadata && (Math.round((nextPageMetadata.pageHeight / nextPageMetadata.pageWidth) * 10) / 10)!== containerRatio;

    return (
        <>
            <PdfPageButton
                show={currentPage > (hasCover ? -1 : 1)}
                direction='previous'
                documentHeight={documentHeight}
                onClick={() => handleSetPageNumber(isSinglePageMode ? currentPage - 1 : currentPage - 2)}
            />

            <DocumentScrollViewer width={scrollContainerWdith} ref={scrollRef}>
                <DocumentPane>
                    {hasLeftPage ? (<PdfView
                        partNumber={partNumber}
                        sectionFileName={currentSection?.fileName}
                        setPageNumber={(pageNumber) => handleSetPageNumber(pageNumber + currentPageOffset)}
                        leftPageNumber={currentPage - currentPageOffset}
                        rightPageNumber={sectionOneHasNextPage ? currentPage - currentPageOffset + 1 : null}
                        documentWidth={documentWidth}
                        documentHeight={documentHeight}
                        offset={currentPageOffset}
                        isOversize={currentSection?.overSizeFile}
                        hasCover={hasCover}
                        currentShowZoomFab={currentPageRatioMisMatch}
                        nextShowZoomFab={nextPageRatioMisMatch}
                    />) : null}
                    {hasRightPage && sectionTwoHasPage ? (
                        <PdfView
                            partNumber={partNumber}
                            sectionFileName={nextSection?.fileName}
                            setPageNumber={(pageNumber) => handleSetPageNumber(pageNumber + nextPageOffset)}
                            rightPageNumber={1}
                            documentWidth={documentWidth}
                            documentHeight={documentHeight}
                            offset={nextPageOffset}
                            isOversize={nextSection?.overSizeFile}
                            hasCover={hasCover}
                            nextShowZoomFab={nextPageRatioMisMatch}
                        />
                    ) : null}
                </DocumentPane>
            </DocumentScrollViewer>

            <PdfPageButton
                show={(isNextPageInCurrentSection || !!nextSection) && nextPageStart <= pageCount}
                direction='next'
                documentHeight={documentHeight}
                onClick={() => handleSetPageNumber(isSinglePageMode ? currentPage + 1 : currentPage + 2)}
            />
        </>
    );
}