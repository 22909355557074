import React, { useMemo, useRef, useState } from 'react';
import { saveAs } from 'file-saver'
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getAuthenticatedHeaders } from '../../../fetch/fetchHeaders';
import Spinner from '../../spinner/Spinner';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, styled, Divider } from '@mui/material';
import { logDocumentDownload, logDownloadFailed } from '../../../fetch/insightsHelper';
import { useSelector } from 'react-redux';
import { useNotification } from '../../../hooks/useNotification';
import { useDocumentMetadata } from '../../../fetch/fetchMetadata';
import { groupArrayByPropertyName } from '../../../helpers/arrayHelpers';
import DownloadSectionMenuItem from './DownloadSectionMenuItem';

const MessageContainer = styled('div')(({ theme, offsetLeft, offsetTop, finished }) => ({
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    color: 'black',
    position: 'absolute',
    width: '20em',
    height: finished ? '8em' : '8em',
    top: offsetTop,
    left: offsetLeft,
}));

export default function DownloadPdfButton() {
    const { partNumber } = useParams();
    const { metadata, isLoading: isMetadataLoading } = useDocumentMetadata(partNumber);
    const { outline, pageCount, sections, hasCover } = metadata;


    let filteredChildren = [];
    if (!!outline && !!outline.children && outline.children.length > 0) {
        filteredChildren = outline.children.filter(it => !!it.pageNumber && !!it.title);
    }

    if (filteredChildren.length === 1 && !!filteredChildren[0].children &&
        filteredChildren[0].children.length > 0) {
        filteredChildren = filteredChildren[0].children.filter(it => !!it.pageNumber && !!it.title);
    }

    const sectionGroups = useMemo(() => {
        if (!!sections && !!filteredChildren) {
            return groupArrayByPropertyName(sections.map(s => {
                const parent = filteredChildren.filter(item => s.start >= (hasCover ? item.pageNumber - 1 : item.pageNumber)).reverse()[0];
                const children = parent.children

                let invalid = parent.isFrontMatter;
                let title = !!parent.sectionNumber ? `${parent.sectionNumber}` : '';
                const sectionTitle = parent.title;
                if (!!children && children.length > 0) {
                    const item = children.filter(c => s.start === (hasCover ? c.pageNumber - 1 : c.pageNumber) && !!c.title)[0];

                    if (!item) {
                        invalid = true;
                    }

                    if (!invalid && !!item.sectionNumber && item.title.length < 5) {
                        invalid = true;
                    }

                    title += item?.title;
                } else {
                    invalid = true;
                }

                return {
                    invalid: invalid,
                    start: s.start,
                    end: s.end,
                    title: title,
                    fileName: s.fileName,
                    sectionTitle: sectionTitle
                };
            }).filter(it => !it.invalid), 'sectionTitle');
        }

        return {};
    }, [sections && filteredChildren]);
    const sectionGroupKeys = Object.keys(sectionGroups);

    const [menuOpen, setMenuOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [downloadFinished, setDownloadFinished] = useState(false);
    const anchorRef = useRef(null);
    const userLanguage = useSelector((state) => state.permissions.language);
    const canDownload = useSelector((state) => state.permissions.canDownload);
    const { showErrorModal } = useNotification();

    if (!canDownload) {
        return null;
    }

    const handleOpenMenu = () => {
        setMenuOpen(true);
        setDrawerOpen(false);
    }

    const handleFullDownload = () => {
        if (drawerOpen) {
            return;
        }

        setMenuOpen(false);
        setDownloadFinished(false);
        setDrawerOpen(true);
        getAuthenticatedHeaders().then((headers) => {
            return fetch(`/document/pdf/${partNumber}.pdf`, { headers })
                .then(response => {
                    return new Promise(function (resolve, reject) {
                        if (response.status >= 400) {
                            reject(response);
                            return
                        }

                        resolve(response);
                        return;
                    });
                })
                .then(response => response.blob())
                .then(blob => saveAs(blob, `${partNumber}.pdf`))
                .then(() => logDocumentDownload(partNumber, userLanguage), handleFullDownloadError)
                .finally(() => setDownloadFinished(true));
        });
    }

    const handleSectionDownload = (sectionFileName, startPage, endPage, displayName) => {
        if (drawerOpen) {
            return;
        }

        let uri = `/document/pdf/${partNumber}/section/${sectionFileName}/watermark`;
        if (!!startPage && !!endPage) {
            uri += `?start=${startPage}&end=${endPage}`;
        }

        setMenuOpen(false);
        setDownloadFinished(false);
        setDrawerOpen(true);
        getAuthenticatedHeaders().then((headers) => {
            return fetch(uri, { headers })
                .then(response => {
                    return new Promise(function (resolve, reject) {
                        if (response.status >= 400) {
                            reject(response);
                            return
                        }

                        resolve(response);
                        return;
                    });
                })
                .then(response => response.blob())
                .then(blob => saveAs(blob, `${partNumber}_${sectionFileName.replace('.pdf', '')}.pdf`))
                .then(() => logDocumentDownload(partNumber, userLanguage, displayName), handleFullDownloadError)
                .finally(() => setDownloadFinished(true));
        });
    }

    const handleFullDownloadError = (error) => {
        console.log('error downloading', error);
        logDownloadFailed(partNumber);
        showErrorModal('An error occurred generating a file for download.')
    }

    return (
        <>
            {drawerOpen ? (
                <MessageContainer
                    offsetLeft={anchorRef?.current?.offsetLeft - 75}
                    offsetTop={anchorRef?.current?.offsetTop * 2.5}
                    finished={downloadFinished}
                >
                    {!downloadFinished ? (
                        <>
                            <div style={{ padding: '0.3em 0.5em', float: 'left' }}>
                                Generating PDF
                            </div>
                            <div style={{ padding: '0.3em 0.5em', float: 'left' }}><Spinner displayInline width={20} /></div>
                            <div style={{ clear: 'both' }}></div>
                        </>
                    ) : null}
                    <div style={{ fontSize: '0.9em' }}>
                        {!downloadFinished ?
                            'This feature will download a PDF File to your downloads folder that can be opened in a PDF Reader application when offline.' :
                            'A PDF File was downloaded to your downloads folder that can be opened in a PDF Reader application when offline.'}
                        <br />
                        {downloadFinished ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant='contained'
                                    style={{ minWidth: 'unset', margin: '0.5em' }}
                                    onClick={() => {
                                        setDrawerOpen(false);
                                        setDownloadFinished(false);
                                    }}
                                >
                                    Ok
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </MessageContainer>
            ) : null}
            {!!metadata && !isMetadataLoading ? (
                <>
                    {filteredChildren?.length > 2 && pageCount > 25 && sectionGroupKeys.length > 1 ? (
                        <Button
                            color="inherit"
                            size='small'
                            onClick={handleOpenMenu}
                            disabled={drawerOpen && !downloadFinished}
                            ref={anchorRef}
                            style={{ border: 0 }}
                        >
                            <FileDownloadIcon />
                            Download
                            <ArrowDropDownIcon />
                        </Button>
                    ) : (
                        <Button
                            color="inherit"
                            title='Download'
                            onClick={() => handleFullDownload()}
                            disabled={drawerOpen && !downloadFinished}
                            ref={anchorRef}
                            style={{ border: 0 }}
                        >
                            <FileDownloadIcon />
                            Download
                        </Button>
                    )}
                </>
            ) : null}
            <Popper
                sx={{ zIndex: 1 }}
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >{({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                            <MenuList autoFocusItem>
                                <MenuItem
                                    onClick={() => handleFullDownload()}>
                                    Full Document
                                </MenuItem>
                                <Divider />
                                {sectionGroupKeys.map((key) => {
                                    const subSections = sectionGroups[key];

                                    return (
                                        <DownloadSectionMenuItem
                                            key={key}
                                            partNumber={partNumber}
                                            hasCover={hasCover}
                                            item={key}
                                            subSections={subSections}
                                            onDownload={handleSectionDownload}
                                        />
                                    );
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
            </Popper>
        </>
    );
}
