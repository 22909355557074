import useSWR from 'swr';

export const useModelEmi = (modelNum) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        !!modelNum ? `/api/v1/engineModel/${modelNum}/emi` : null,
        fetcher,
        swrConfig);

    return { data, error, isValidating, isLoading, mutate };
}

const fetcher = (url) => {
    return fetch(url)
        .then(response => response.json());
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};