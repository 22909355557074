import React from "react";
import ContentContainer from "./contentContainer";
import { apiHostUrl } from "../../helpers";
import './g3SupportUpdates.css'

export default function G3SupportUpdates() {
    const downloadPageUrl = `${apiHostUrl}/g3/download`;
    return (
        <ContentContainer>
            <div style={{ height: '1200px' }}>
                <link rel="stylesheet" href="https://use.typekit.net/fkf6yxx.css" />
                <link rel="stylesheet" href="https://use.typekit.net/gbl1yyn.css" />
                <div className="g3-update">
                    <div className="update-container">
                        <img src="G3ModuleData.png" />
                        <div className="update-copy">
                            <div className="update-header">
                                <h3>CDS G3 2025 is Now Available</h3>
                                <p>This update includes feature enhancements, numerous improvements and bug fixes. Software is available to download from: <a href={downloadPageUrl} target="_blank">{downloadPageUrl} <i className="fas fa-external-link-alt" aria-hidden="true"></i></a></p>
                            </div>

                            <div className="update-body">
                                <div className="update-body__main">
                                    <h4>License Keys</h4>
                                    <p>A license key for CDS G3 is required and must be obtained by one of the following methods:</p>
                                </div>

                                <div className="dealer-info">
                                    <div className="dealer-instruction">
                                        <div className="dealer-instruction__title">
                                            <h5>North American Dealers Only</h5>
                                        </div>
                                        <div className="dealer-instruction__copy">
                                            <p>If your dealership participated in this year's annual billing for CDS G3, your CDS G3 license key is now viewable on MercNET and ready to use.</p>
                                            <p>Refer to CDS G3 license information on <a href="https://mercnet.mercurymarine.com/" target="_blank">MercNET <i className="fas fa-external-link-alt" aria-hidden="true"></i></a>.</p>
                                        </div>
                                    </div>
                                    <div className="dealer-instruction">
                                        <div className="dealer-instruction__title">
                                            <h5>All Other Dealers</h5>
                                        </div>
                                        <div className="dealer-instruction__copy">
                                            <p>Please order:</p>
                                            <ul>
                                                <li>
                                                    <strong>CDS G3 License Key P/N: 8M0234005</strong>
                                                    <span>One must be ordered for each device running CDS G3</span>
                                                </li>
                                                <li>
                                                    <strong>CDS G3 Kit P/N: 8M0168921</strong>
                                                    <span>
                                                        Includes cables, and SmartCraft interface, does NOT include License
                                                        <br />
                                                        <img title='CDS G3 License Key P/N: 8M0215503' src='g3-smartcraft-kit.jpg' style={{ maxHeight: '150px' }} />
                                                    </span>
                                                    <ol type='a'>
                                                        <li style={{ lineHeight: 1, marginBottom: '0.25em' }}>CDS G3 SmartCraft diagnostic interface - 898289T81</li>
                                                        <li style={{ lineHeight: 1, marginBottom: '0.25em' }}>CAN P/CAN H adapter harness - 898289T83</li>
                                                        <li style={{ lineHeight: 1, marginBottom: '0.25em' }}>CDS G3 engine harness adapter (with termination resistor) - 8M0046081</li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    <strong>CDS G3 Serial Kit P/N: 8M0137534</strong>
                                                    <span>Includes serial cables, and serial interface, does NOT include License</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
}