import { Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { preloadDocumentSectionPdf, useDocumentSectionPdf } from "../../../fetch/fetchPdf";
import { useIsInViewport } from "../../../hooks";
import ScrollSectionPagePane from "./ScrollSectionPagePane";
import ScrollSectionPagePaneDocumentView from './ScrollSectionPagePaneDocumentView';
import ZoomablePageFab from "../../documentViewCore/ZoomablePageFab";

export default function ScrollSectionPane({
    section, previousSection, nextSection,
    partNumber, pages, hasCover, pageCount, documentDimensions, onComeIntoVisibility, setPageNumber,
    onToggleZoom }) {
    const currentPageNumber = +(window.location.hash.replace('#page=', ''));

    const { fileName, start, end } = section;
    const sectionContainerRef = useRef(null);
    const [isSectionInViewport, intersectionRatio] = useIsInViewport(sectionContainerRef);
    const isInViewportOrCurrentPage = isSectionInViewport || (section?.start <= currentPageNumber && currentPageNumber <= section?.end);

    const [docRendered, setDocRendered] = useState(false);

    useEffect(() => {
        sectionContainerRef.current.id = fileName
    }, [sectionContainerRef?.current]);

    const offset = start - 1;

    const { pdfData, isLoading: isPdfLoading } = useDocumentSectionPdf(partNumber, isInViewportOrCurrentPage ? fileName : null);

    const canPreloadNext = !!nextSection && currentPageNumber + 4 >= nextSection.start
    const canPreloadPrevious = !!previousSection && currentPageNumber - 2 <= previousSection.end;

    useEffect(() => {
        if (isInViewportOrCurrentPage && canPreloadNext) {
            preloadDocumentSectionPdf(partNumber, nextSection.fileName);
        }
    }, [isInViewportOrCurrentPage, canPreloadNext]);

    useEffect(() => {
        if (isInViewportOrCurrentPage && canPreloadPrevious) {
            preloadDocumentSectionPdf(partNumber, previousSection.fileName);
        }
    }, [isInViewportOrCurrentPage, canPreloadPrevious]);

    const containerRatio = Math.round((documentDimensions.documentHeight / documentDimensions.documentWidth) * 10) / 10;

    return (
        <Stack justifyContent='center' ref={sectionContainerRef} style={{ minHeight: documentDimensions.documentHeight * pages.length }}>
            {pages.map((page) => {
                const { pageNumber, pageHeight, pageWidth } = page;
                const pageRatio = Math.round((pageHeight / pageWidth) * 10) / 10;

                return (
                    <ScrollSectionPagePane
                        key={pageNumber}
                        hasCover={hasCover}
                        pageCount={pageCount}
                        documentDimensions={documentDimensions}
                        partNumber={partNumber}
                        pageNumber={pageNumber}
                        onComeIntoVisibility={onComeIntoVisibility}
                        isSectionInViewport={isInViewportOrCurrentPage}
                        onToggleZoom={onToggleZoom}
                        pdfRendered={!!pdfData && docRendered}
                    >
                        {containerRatio != pageRatio ? (
                            <ZoomablePageFab
                                partNumber={partNumber}
                                pageNumber={pageNumber}
                                position='right'
                            />
                        ) : null}
                        {!!pdfData && !isPdfLoading ? (
                            <ScrollSectionPagePaneDocumentView
                                pdfData={pdfData}
                                pageNumber={pageNumber - offset}
                                setPageNumber={(pageNumber) => setPageNumber(pageNumber + offset)}
                                documentDimensions={documentDimensions}
                                onToggleZoom={onToggleZoom}
                                docRendered={docRendered}
                                onDocRendered={(rendered) => setDocRendered(rendered)}
                            />
                        ) : null}
                    </ScrollSectionPagePane>
                );
            })}
        </Stack>
    );
}