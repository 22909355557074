import styled from '@emotion/styled';
import { IconButton, InputAdornment, InputBase, alpha } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

const Search = styled('div')(({ theme, border }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
    border: !!border ? `${border}px solid #B5B5B5` : null
}));

const SearchIconWrapper = styled('div')(({ theme, wide }) => ({
    padding: wide ? theme.spacing(0, 2) : theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme, wide }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: wide ? `calc(1em + ${theme.spacing(4)})` : `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: wide ? '35ch' : '24ch',
        },
        [theme.breakpoints.down('md')]: {
            width: wide ? '25ch' : '24ch',
        },
    },
}));

const ClearButton = styled(IconButton)(({ theme, isLightVersion }) => ({
    color: !isLightVersion? theme.palette.primary.contrastText : theme.palette.primary.main,
}));

export default function SearchField({ placeholder, query, onChange, onKeyDown, onClearQuery, showClear, isLightVersion }) {
    return (
        <Search border={isLightVersion ? 1 : null}>
            <SearchIconWrapper wide={!isLightVersion}>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                value={query}
                onChange={onChange}
                onKeyDown={onKeyDown}
                wide={!isLightVersion}
                endAdornment={
                    showClear ? (
                        <InputAdornment position="end">
                            <ClearButton onClick={onClearQuery} isLightVersion={isLightVersion}>
                                <CancelIcon />
                            </ClearButton>
                        </InputAdornment>
                    ) : null
                }
            />
        </Search>
    );
}