import styled from '@emotion/styled';

const NewBanner = styled('div')(({ theme }) => {
    let color = theme.palette.warning.main;
    let contrastColor = theme.palette.warning.contrastText;

    return ({
        backgroundColor: color,
        color: contrastColor,
        marginLeft: 0,
        width: '9.5em',
        padding: theme.spacing(0.5),
        textAlign: 'center',
        fontWeight: 450,
        transform: 'translateY(3em) translateX(-1.5em) rotate(-45deg)',
        transformOrigin: 'bottom left',
        display: 'inline',
        zIndex: 9,
        position: 'absolute'
    });
});

export default NewBanner;