import { Fab, Dialog, DialogContent, Typography, AppBar, Toolbar, IconButton, Button } from "@mui/material";
import React, { useState } from "react";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { usePageImage } from "../../fetch/fetchImage";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';

export default function ZoomablePageFab({ partNumber, pageNumber, position }) {
    const [showZoom, setShowZoom] = useState(false);
    const { image } = usePageImage(partNumber, pageNumber);

    const handleOpen = () => {
        setShowZoom(true);
    }

    const handleClose = () => {
        setShowZoom(false);
    }

    return (
        <div onDoubleClick={(e) => e.stopPropagation()}>
            {!showZoom ?
                (
                    <Fab
                        size='small'
                        color='primary'
                        style={{ marginRight: '2em', float: position }}
                        onClick={handleOpen}
                    >
                        <ZoomInIcon />
                    </Fab>
                ) : null}
            <Dialog
                style={{ zIndex: 9999 }}
                fullScreen
                open={showZoom}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Zoom
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TransformWrapper minScale={0.25}>
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <>
                                <Controls />
                                <TransformComponent wrapperStyle={{ maxWidth: '100%', maxHeight: '100%' }}>
                                    <img src={image} alt={`Page ${pageNumber}`} />
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
        <div className="tools">
            <Button title='Zoom In' onClick={() => zoomIn()}><AddIcon /></Button>
            <Button title='Zoom Out' onClick={() => zoomOut()}><RemoveIcon /></Button>
            <Button title='Reset' onClick={() => resetTransform()}><ClearIcon /></Button>
        </div>
    );
};