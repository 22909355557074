import styled from '@emotion/styled';

const RecallBanner = styled('div')(({ theme, isNew }) => {
    let color = theme.palette.error.main;
    let contrastColor = theme.palette.error.contrastText;

    return ({
        backgroundColor: color,
        color: contrastColor,
        marginLeft: 0,
        width: isNew ? '14em' : '9.5em',
        padding: theme.spacing(0.5),
        textAlign: 'center',
        fontWeight: 450,
        transform: isNew ? 'translateY(6em) translateX(-1.5em) rotate(-45deg)' : 'translateY(3em) translateX(-1.5em) rotate(-45deg)',
        transformOrigin: 'bottom left',
        display: 'inline',
        zIndex: 9,
        position: 'absolute'
    });
});

export default RecallBanner;